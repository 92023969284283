/* You can add global styles to this file, and also import other style files */

/*@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";*/
@import "~materialize-css/dist/css/materialize.min.css";

body { 
    margin : 0;
    width: 100%;
    height: 100%
 }

.slideout-menu {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    width: 256px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    display: none;
  }

  .slideout-panel {
    position: relative;
    z-index: 1;
    will-change: transform;
  }

  .slideout-open,
  .slideout-open body,
  .slideout-open .slideout-panel {
    overflow: hidden;
  }

  .slideout-open .slideout-menu {
    display: block;
  }
  .pointer {
    cursor : pointer;
  }
  

h1 {
  font-size: 2.2rem !important;
}

h2 {
  font-size: 1.2rem !important;
}

h3 {
  font-size: 1.0rem !important;
}